.login-card {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  height: 100%;
  max-height: 200px
}

.login-card-inner {
  width: 100%;
  height: 100%;
}

.login-button {
  text-decoration: none;
}
